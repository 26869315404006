

























































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class Suppliers extends Vue {
  public suppliers: any = [];
  public onlyActive: boolean = true;
  public result: any = null;
  public filter: any = {
    supplierId: null,
    invoiceNumber: null,
    year: null,
  };

  private async getSuppliers() {
    this.suppliers = await adminService.getSuppliers(this.onlyActive, organisationModule.organisation.id);
  }

  private async findInvoices() {
    this.result = await adminService.findInvoices(organisationModule.organisation.id, this.filter);
  }

  public async mounted() {
    await this.getSuppliers();
  }
}
